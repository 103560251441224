/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

export default function Charger(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('./model/charger.glb');
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Cube099"
          castShadow
          receiveShadow
          geometry={nodes.Cube099.geometry}
          material={materials['Cable-white']}
          position={[-1.765, -0.354, -1.092]}
        />
        <mesh
          name="Cylinder004"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder004.geometry}
          material={materials['Cable-white']}
          position={[-1.777, 1.411, -1.077]}
        />
        <mesh
          name="Cylinder005"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder005.geometry}
          material={materials['Cable-white']}
          position={[-1.848, 1.355, -0.124]}
        />
      </group>
    </group>
  );
}

useGLTF.preload('./model/charger.glb');
