/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

export default function Computer(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('./model/computer.glb');
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Cube004"
          castShadow
          receiveShadow
          geometry={nodes.Cube004.geometry}
          material={materials.macbook}
          position={[-1.356, 1.355, -0.195]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube007"
          castShadow
          receiveShadow
          geometry={nodes.Cube007.geometry}
          material={materials.macbook}
          position={[-1.575, 1.647, -0.509]}
          rotation={[1.355, 0.149, 2.547]}
          scale={[-0.42, -0.013, -0.294]}
        />
        <mesh
          name="Cube008"
          castShadow
          receiveShadow
          geometry={nodes.Cube008.geometry}
          material={materials['macbook-keys']}
          position={[-1.778, 1.355, -0.181]}
          rotation={[0, 0.611, 0]}
          scale={[0.433, 0.42, 0.42]}
        />
        <mesh
          name="Cube010"
          castShadow
          receiveShadow
          geometry={nodes.Cube010.geometry}
          material={materials['macbook-keys']}
          position={[-1.698, 1.355, -0.237]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube011"
          castShadow
          receiveShadow
          geometry={nodes.Cube011.geometry}
          material={materials['macbook-keys']}
          position={[-1.66, 1.355, -0.264]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube012"
          castShadow
          receiveShadow
          geometry={nodes.Cube012.geometry}
          material={materials['macbook-keys']}
          position={[-1.622, 1.355, -0.29]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube013"
          castShadow
          receiveShadow
          geometry={nodes.Cube013.geometry}
          material={materials['macbook-keys']}
          position={[-1.584, 1.355, -0.317]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube014"
          castShadow
          receiveShadow
          geometry={nodes.Cube014.geometry}
          material={materials['macbook-keys']}
          position={[-1.547, 1.355, -0.343]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube015"
          castShadow
          receiveShadow
          geometry={nodes.Cube015.geometry}
          material={materials['macbook-keys']}
          position={[-1.509, 1.355, -0.37]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube016"
          castShadow
          receiveShadow
          geometry={nodes.Cube016.geometry}
          material={materials['macbook-keys']}
          position={[-1.471, 1.355, -0.396]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube017"
          castShadow
          receiveShadow
          geometry={nodes.Cube017.geometry}
          material={materials['macbook-keys']}
          position={[-1.433, 1.355, -0.423]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube018"
          castShadow
          receiveShadow
          geometry={nodes.Cube018.geometry}
          material={materials['macbook-keys']}
          position={[-1.395, 1.355, -0.449]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube019"
          castShadow
          receiveShadow
          geometry={nodes.Cube019.geometry}
          material={materials['macbook-keys']}
          position={[-1.357, 1.355, -0.476]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube020"
          castShadow
          receiveShadow
          geometry={nodes.Cube020.geometry}
          material={materials['macbook-keys']}
          position={[-1.32, 1.355, -0.502]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube021"
          castShadow
          receiveShadow
          geometry={nodes.Cube021.geometry}
          material={materials['macbook-keys']}
          position={[-1.282, 1.355, -0.529]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube022"
          castShadow
          receiveShadow
          geometry={nodes.Cube022.geometry}
          material={materials['macbook-keys']}
          position={[-1.244, 1.355, -0.555]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube023"
          castShadow
          receiveShadow
          geometry={nodes.Cube023.geometry}
          material={materials['macbook-keys']}
          position={[-1.206, 1.355, -0.582]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube024"
          castShadow
          receiveShadow
          geometry={nodes.Cube024.geometry}
          material={materials['macbook-keys']}
          position={[-1.727, 1.355, -0.161]}
          rotation={[0, 0.611, 0]}
          scale={[0.445, 0.42, 0.42]}
        />
        <mesh
          name="Cube025"
          castShadow
          receiveShadow
          geometry={nodes.Cube025.geometry}
          material={materials['macbook-keys']}
          position={[-1.689, 1.355, -0.187]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube026"
          castShadow
          receiveShadow
          geometry={nodes.Cube026.geometry}
          material={materials['macbook-keys']}
          position={[-1.651, 1.355, -0.214]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube027"
          castShadow
          receiveShadow
          geometry={nodes.Cube027.geometry}
          material={materials['macbook-keys']}
          position={[-1.613, 1.355, -0.24]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube028"
          castShadow
          receiveShadow
          geometry={nodes.Cube028.geometry}
          material={materials['macbook-keys']}
          position={[-1.575, 1.355, -0.267]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube029"
          castShadow
          receiveShadow
          geometry={nodes.Cube029.geometry}
          material={materials['macbook-keys']}
          position={[-1.537, 1.355, -0.293]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube030"
          castShadow
          receiveShadow
          geometry={nodes.Cube030.geometry}
          material={materials['macbook-keys']}
          position={[-1.499, 1.355, -0.32]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube031"
          castShadow
          receiveShadow
          geometry={nodes.Cube031.geometry}
          material={materials['macbook-keys']}
          position={[-1.462, 1.355, -0.346]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube032"
          castShadow
          receiveShadow
          geometry={nodes.Cube032.geometry}
          material={materials['macbook-keys']}
          position={[-1.424, 1.355, -0.373]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube033"
          castShadow
          receiveShadow
          geometry={nodes.Cube033.geometry}
          material={materials['macbook-keys']}
          position={[-1.386, 1.355, -0.399]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube034"
          castShadow
          receiveShadow
          geometry={nodes.Cube034.geometry}
          material={materials['macbook-keys']}
          position={[-1.348, 1.355, -0.426]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube035"
          castShadow
          receiveShadow
          geometry={nodes.Cube035.geometry}
          material={materials['macbook-keys']}
          position={[-1.31, 1.355, -0.452]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube036"
          castShadow
          receiveShadow
          geometry={nodes.Cube036.geometry}
          material={materials['macbook-keys']}
          position={[-1.272, 1.355, -0.479]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube037"
          castShadow
          receiveShadow
          geometry={nodes.Cube037.geometry}
          material={materials['macbook-keys']}
          position={[-1.235, 1.355, -0.505]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube038"
          castShadow
          receiveShadow
          geometry={nodes.Cube038.geometry}
          material={materials['macbook-keys']}
          position={[-1.186, 1.355, -0.539]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube039"
          castShadow
          receiveShadow
          geometry={nodes.Cube039.geometry}
          material={materials['macbook-keys']}
          position={[-1.719, 1.355, -0.11]}
          rotation={[0, 0.611, 0]}
          scale={[0.346, 0.42, 0.42]}
        />
        <mesh
          name="Cube009"
          castShadow
          receiveShadow
          geometry={nodes.Cube009.geometry}
          material={materials['macbook-keys']}
          position={[-1.679, 1.355, -0.138]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube040"
          castShadow
          receiveShadow
          geometry={nodes.Cube040.geometry}
          material={materials['macbook-keys']}
          position={[-1.642, 1.355, -0.164]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube041"
          castShadow
          receiveShadow
          geometry={nodes.Cube041.geometry}
          material={materials['macbook-keys']}
          position={[-1.604, 1.355, -0.191]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube042"
          castShadow
          receiveShadow
          geometry={nodes.Cube042.geometry}
          material={materials['macbook-keys']}
          position={[-1.566, 1.355, -0.217]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube043"
          castShadow
          receiveShadow
          geometry={nodes.Cube043.geometry}
          material={materials['macbook-keys']}
          position={[-1.528, 1.355, -0.244]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube044"
          castShadow
          receiveShadow
          geometry={nodes.Cube044.geometry}
          material={materials['macbook-keys']}
          position={[-1.49, 1.355, -0.27]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube045"
          castShadow
          receiveShadow
          geometry={nodes.Cube045.geometry}
          material={materials['macbook-keys']}
          position={[-1.452, 1.355, -0.297]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube046"
          castShadow
          receiveShadow
          geometry={nodes.Cube046.geometry}
          material={materials['macbook-keys']}
          position={[-1.414, 1.355, -0.323]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube047"
          castShadow
          receiveShadow
          geometry={nodes.Cube047.geometry}
          material={materials['macbook-keys']}
          position={[-1.377, 1.355, -0.35]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube048"
          castShadow
          receiveShadow
          geometry={nodes.Cube048.geometry}
          material={materials['macbook-keys']}
          position={[-1.339, 1.355, -0.376]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube049"
          castShadow
          receiveShadow
          geometry={nodes.Cube049.geometry}
          material={materials['macbook-keys']}
          position={[-1.301, 1.355, -0.403]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube050"
          castShadow
          receiveShadow
          geometry={nodes.Cube050.geometry}
          material={materials['macbook-keys']}
          position={[-1.263, 1.355, -0.429]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube051"
          castShadow
          receiveShadow
          geometry={nodes.Cube051.geometry}
          material={materials['macbook-keys']}
          position={[-1.225, 1.355, -0.456]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube052"
          castShadow
          receiveShadow
          geometry={nodes.Cube052.geometry}
          material={materials['macbook-keys']}
          position={[-1.187, 1.355, -0.482]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube053"
          castShadow
          receiveShadow
          geometry={nodes.Cube053.geometry}
          material={materials['macbook-keys']}
          position={[-1.15, 1.355, -0.509]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube054"
          castShadow
          receiveShadow
          geometry={nodes.Cube054.geometry}
          material={materials['macbook-keys']}
          position={[-1.68, 1.355, -0.081]}
          rotation={[0, 0.611, 0]}
          scale={[0.372, 0.42, 0.42]}
        />
        <mesh
          name="Cube055"
          castShadow
          receiveShadow
          geometry={nodes.Cube055.geometry}
          material={materials['macbook-keys']}
          position={[-1.646, 1.355, -0.104]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube056"
          castShadow
          receiveShadow
          geometry={nodes.Cube056.geometry}
          material={materials['macbook-keys']}
          position={[-1.608, 1.355, -0.131]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube057"
          castShadow
          receiveShadow
          geometry={nodes.Cube057.geometry}
          material={materials['macbook-keys']}
          position={[-1.57, 1.355, -0.157]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube058"
          castShadow
          receiveShadow
          geometry={nodes.Cube058.geometry}
          material={materials['macbook-keys']}
          position={[-1.532, 1.355, -0.184]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube059"
          castShadow
          receiveShadow
          geometry={nodes.Cube059.geometry}
          material={materials['macbook-keys']}
          position={[-1.495, 1.355, -0.21]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube060"
          castShadow
          receiveShadow
          geometry={nodes.Cube060.geometry}
          material={materials['macbook-keys']}
          position={[-1.457, 1.355, -0.237]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube061"
          castShadow
          receiveShadow
          geometry={nodes.Cube061.geometry}
          material={materials['macbook-keys']}
          position={[-1.419, 1.355, -0.263]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube062"
          castShadow
          receiveShadow
          geometry={nodes.Cube062.geometry}
          material={materials['macbook-keys']}
          position={[-1.381, 1.355, -0.29]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube063"
          castShadow
          receiveShadow
          geometry={nodes.Cube063.geometry}
          material={materials['macbook-keys']}
          position={[-1.343, 1.355, -0.316]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube064"
          castShadow
          receiveShadow
          geometry={nodes.Cube064.geometry}
          material={materials['macbook-keys']}
          position={[-1.305, 1.355, -0.343]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube065"
          castShadow
          receiveShadow
          geometry={nodes.Cube065.geometry}
          material={materials['macbook-keys']}
          position={[-1.268, 1.355, -0.369]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube066"
          castShadow
          receiveShadow
          geometry={nodes.Cube066.geometry}
          material={materials['macbook-keys']}
          position={[-1.23, 1.355, -0.396]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube067"
          castShadow
          receiveShadow
          geometry={nodes.Cube067.geometry}
          material={materials['macbook-keys']}
          position={[-1.192, 1.355, -0.422]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube068"
          castShadow
          receiveShadow
          geometry={nodes.Cube068.geometry}
          material={materials['macbook-keys']}
          position={[-1.154, 1.355, -0.449]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube070"
          castShadow
          receiveShadow
          geometry={nodes.Cube070.geometry}
          material={materials['macbook-keys']}
          position={[-1.599, 1.355, -0.081]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube071"
          castShadow
          receiveShadow
          geometry={nodes.Cube071.geometry}
          material={materials['macbook-keys']}
          position={[-1.561, 1.355, -0.108]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube072"
          castShadow
          receiveShadow
          geometry={nodes.Cube072.geometry}
          material={materials['macbook-keys']}
          position={[-1.523, 1.355, -0.134]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube073"
          castShadow
          receiveShadow
          geometry={nodes.Cube073.geometry}
          material={materials['macbook-keys']}
          position={[-1.485, 1.355, -0.161]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube074"
          castShadow
          receiveShadow
          geometry={nodes.Cube074.geometry}
          material={materials['macbook-keys']}
          position={[-1.447, 1.355, -0.187]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube075"
          castShadow
          receiveShadow
          geometry={nodes.Cube075.geometry}
          material={materials['macbook-keys']}
          position={[-1.41, 1.355, -0.214]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube076"
          castShadow
          receiveShadow
          geometry={nodes.Cube076.geometry}
          material={materials['macbook-keys']}
          position={[-1.372, 1.355, -0.24]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube077"
          castShadow
          receiveShadow
          geometry={nodes.Cube077.geometry}
          material={materials['macbook-keys']}
          position={[-1.334, 1.355, -0.267]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube078"
          castShadow
          receiveShadow
          geometry={nodes.Cube078.geometry}
          material={materials['macbook-keys']}
          position={[-1.296, 1.355, -0.293]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube079"
          castShadow
          receiveShadow
          geometry={nodes.Cube079.geometry}
          material={materials['macbook-keys']}
          position={[-1.258, 1.355, -0.32]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube080"
          castShadow
          receiveShadow
          geometry={nodes.Cube080.geometry}
          material={materials['macbook-keys']}
          position={[-1.22, 1.355, -0.346]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube081"
          castShadow
          receiveShadow
          geometry={nodes.Cube081.geometry}
          material={materials['macbook-keys']}
          position={[-1.183, 1.355, -0.373]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube082"
          castShadow
          receiveShadow
          geometry={nodes.Cube082.geometry}
          material={materials['macbook-keys']}
          position={[-1.657, 1.355, -0.04]}
          rotation={[0, 0.611, 0]}
          scale={[0.832, 0.42, 0.42]}
        />
        <mesh
          name="Cube069"
          castShadow
          receiveShadow
          geometry={nodes.Cube069.geometry}
          material={materials['macbook-keys']}
          position={[-1.145, 1.355, -0.399]}
          rotation={[0, 0.611, 0]}
          scale={[0.454, 0.42, 0.42]}
        />
        <mesh
          name="Cube083"
          castShadow
          receiveShadow
          geometry={nodes.Cube083.geometry}
          material={materials['macbook-keys']}
          position={[-1.645, 1.355, 0.007]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube084"
          castShadow
          receiveShadow
          geometry={nodes.Cube084.geometry}
          material={materials['macbook-keys']}
          position={[-1.607, 1.355, -0.019]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube085"
          castShadow
          receiveShadow
          geometry={nodes.Cube085.geometry}
          material={materials['macbook-keys']}
          position={[-1.569, 1.355, -0.046]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube086"
          castShadow
          receiveShadow
          geometry={nodes.Cube086.geometry}
          material={materials['macbook-keys']}
          position={[-1.513, 1.355, -0.085]}
          rotation={[0, 0.611, 0]}
          scale={[0.372, 0.42, 0.42]}
        />
        <mesh
          name="Cube087"
          castShadow
          receiveShadow
          geometry={nodes.Cube087.geometry}
          material={materials['macbook-keys']}
          position={[-1.356, 1.355, -0.195]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube088"
          castShadow
          receiveShadow
          geometry={nodes.Cube088.geometry}
          material={materials['macbook-keys']}
          position={[-1.214, 1.355, -0.294]}
          rotation={[0, 0.611, 0]}
          scale={[0.372, 0.42, 0.42]}
        />
        <mesh
          name="Cube089"
          castShadow
          receiveShadow
          geometry={nodes.Cube089.geometry}
          material={materials['macbook-keys']}
          position={[-1.18, 1.355, -0.318]}
          rotation={[0, 0.611, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube090"
          castShadow
          receiveShadow
          geometry={nodes.Cube090.geometry}
          material={materials['macbook-keys']}
          position={[-1.143, 1.355, -0.344]}
          rotation={[0, 0.611, 0]}
          scale={[0.378, 0.42, 0.42]}
        />
        <mesh
          name="Cube091"
          castShadow
          receiveShadow
          geometry={nodes.Cube091.geometry}
          material={materials['macbook-keys']}
          position={[-1.108, 1.355, -0.369]}
          rotation={[0, 0.611, 0]}
          scale={[0.378, 0.42, 0.42]}
        />
        <mesh
          name="Cube092"
          castShadow
          receiveShadow
          geometry={nodes.Cube092.geometry}
          material={materials['macbook-keys']}
          position={[-1.071, 1.355, -0.394]}
          rotation={[0, 0.611, 0]}
          scale={[0.378, 0.42, 0.42]}
        />
        <mesh
          name="Cube093"
          castShadow
          receiveShadow
          geometry={nodes.Cube093.geometry}
          material={materials['macbook-keys']}
          position={[-1.12, 1.355, -0.386]}
          rotation={[0, 0.611, 0]}
          scale={[0.378, 0.42, 0.42]}
        />
        <mesh
          name="Cube094"
          castShadow
          receiveShadow
          geometry={nodes.Cube094.geometry}
          material={materials.monitorScreen}
          position={[-1.575, 1.647, -0.509]}
          rotation={[1.355, 0.149, 2.547]}
          scale={[-0.42, -0.013, -0.294]}
        />
      </group>
    </group>
  );
}

useGLTF.preload('./model/computer.glb');
