/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

export default function Mouse(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('./model/mouse.glb');
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Cube101"
          castShadow
          receiveShadow
          geometry={nodes.Cube101.geometry}
          material={materials['Material.002']}
          position={[0.547, 1.349, 0]}
          rotation={[Math.PI, -0.175, Math.PI]}
          scale={0.056}
        />
        <mesh
          name="Cylinder007"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder007.geometry}
          material={materials.macbook}
          position={[0.547, 1.349, 0]}
          rotation={[Math.PI, -0.175, Math.PI]}
          scale={0.056}
        />
      </group>
    </group>
  );
}

useGLTF.preload('./model/mouse.glb');
