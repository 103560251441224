import './style.css';
import ReactDOM from 'react-dom/client';
import { Canvas } from '@react-three/fiber';
import Experience from './Experience.jsx';

const root = ReactDOM.createRoot(document.querySelector('#root'));

root.render(
  <Canvas
    flat
    shadows
    camera={{
      fov: 45,
      near: 0.1,
      far: 200,
      position: [5, 4.8, 5],
    }}
  >
    <Experience />
    <color attach="background" args={['#000000']} />
  </Canvas>
);
