import {
  OrbitControls,
  Text3D,
  Center,
  useMatcapTexture,
} from '@react-three/drei';
import Monitor from './Monitor';
import Desk from './Desk';
import Computer from './Computer';
import Chair from './Chair';
import Charger from './Charger';
import Keyboard from './Keyboard';
import MousePad from './MousePad';
import Mouse from './Mouse';

export default function Experience() {
  const [matcapTexture] = useMatcapTexture('3B3C3F_DAD9D5_929290_ABACA8');

  return (
    <>
      <OrbitControls makeDefault />

      <directionalLight
        castShadow
        position={[-1, 2, 3]}
        intensity={0.8}
        shadow-normalBias={0.04}
      />
      <ambientLight intensity={0.5} />

      {/* <Environment preset="city" /> */}

      <Center position={[-8, 0, -8]} rotation={[0, 0.8, 0]}>
        <Text3D
          font="./fonts/helvetiker_regular.typeface.json"
          size={0.5}
          height={0.2}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelOffset={0}
          bevelSegments={5}
          position={[2, 0.8, 0]}
        >
          Scriptdrift
          <meshMatcapMaterial matcap={matcapTexture} />
        </Text3D>
        <Text3D
          font="./fonts/helvetiker_regular.typeface.json"
          size={0.5}
          height={0.2}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelOffset={0}
          bevelSegments={5}
        >
          Software Consulting AB
          <meshMatcapMaterial matcap={matcapTexture} />
        </Text3D>
      </Center>

      <mesh receiveShadow rotation-x={-Math.PI / 2} scale={7}>
        <planeGeometry />
        <meshStandardMaterial color="darkblue" />
      </mesh>

      <Monitor position={[0.5, 0, 0]} />
      <Desk position={[0.5, 0, 0]} />
      <Computer position={[0.5, -0.005, 0]} />
      <Chair scale={1.1} position={[0.65, 0, 0]} />
      <Charger position={[0.5, 0, 0]} />
      <Keyboard position={[0.3, 0, 0]} />
      <Mouse position={[0.3, 0, -0.25]} rotation={[0, -0.4, 0]} />
      <MousePad position={[0.3, 0, 0]} />
    </>
  );
}
