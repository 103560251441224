/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

export default function Desk(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('./model/desk.glb');
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Cube"
          castShadow
          receiveShadow
          geometry={nodes.Cube.geometry}
          material={materials['Table legs']}
          position={[0.49, -0.626, -0.402]}
          rotation={[-Math.PI, 0, 0]}
          scale={[-1, -1, -0.7]}
        />
        <mesh
          name="Cube001"
          castShadow
          receiveShadow
          geometry={nodes.Cube001.geometry}
          material={materials['Table legs']}
          position={[-1.51, -0.626, -0.402]}
          rotation={[-Math.PI, 0, 0]}
          scale={[-1, -1, -0.7]}
        />
        <mesh
          name="Cube002"
          castShadow
          receiveShadow
          geometry={nodes.Cube002.geometry}
          material={materials.tableTop}
          position={[-0.51, 1.32, -0.402]}
          scale={[-1.389, -0.02, -0.8]}
        />
        <mesh
          name="Cube003"
          castShadow
          receiveShadow
          geometry={nodes.Cube003.geometry}
          material={materials.hightControl}
          position={[0.51, 1.27, 0.402]}
          rotation={[2.841, 0, 0]}
          scale={[-0.09, -0.03, -0.003]}
        />
        <mesh
          name="Cube097"
          castShadow
          receiveShadow
          geometry={nodes.Cube097.geometry}
          material={materials.outlet}
          position={[-0.51, 1.32, -0.402]}
        />
        <mesh
          name="Cube098"
          castShadow
          receiveShadow
          geometry={nodes.Cube098.geometry}
          material={materials.outlet}
          position={[-0.51, 1.32, -0.402]}
        />
      </group>
    </group>
  );
}

useGLTF.preload('./model/desk.glb');
