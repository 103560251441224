/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

export default function Monitor(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('./model/monitor.glb');
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Cylinder"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder.geometry}
          material={materials.monitorStand}
          position={[-0.149, 1.384, -0.786]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.209, 0.029]}
        />
        <mesh
          name="Cylinder001"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder001.geometry}
          material={materials.monitorStand}
          position={[-0.149, 1.983, -0.748]}
          rotation={[-Math.PI / 2, 0, -Math.PI]}
          scale={0.026}
        />
        <mesh
          name="Cube005"
          castShadow
          receiveShadow
          geometry={nodes.Cube005.geometry}
          material={materials.monitorStand}
          position={[-0.149, 1.984, -0.707]}
          rotation={[-Math.PI, 0, 0]}
          scale={[-0.968, -0.46, -0.014]}
        />
        <mesh
          name="Cube006"
          castShadow
          receiveShadow
          geometry={nodes.Cube006.geometry}
          material={materials.monitorScreen}
          position={[-0.149, 1.984, -0.707]}
          rotation={[-Math.PI, 0, 0]}
          scale={[-0.968, -0.46, -0.014]}
        />
        <mesh
          name="Cylinder002"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder002.geometry}
          material={materials.monitorStand}
          position={[-0.168, 1.383, -0.016]}
          rotation={[0, Math.PI / 2, 0]}
          scale={0.778}
        />
        <group name="NurbsPath" position={[0, -0.354, 0]} />
        <mesh
          name="Cylinder003"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder003.geometry}
          material={materials['cable-dark']}
          position={[0, -0.354, 0]}
        />
        <mesh
          name="Cube095"
          castShadow
          receiveShadow
          geometry={nodes.Cube095.geometry}
          material={materials['cable-dark']}
          position={[-1.105, 1.352, -0.598]}
          rotation={[0, 0.625, 0]}
        />
        <mesh
          name="Cube096"
          castShadow
          receiveShadow
          geometry={nodes.Cube096.geometry}
          material={materials['cable-dark']}
          position={[-0.276, 1.858, -0.738]}
          rotation={[0, 1.564, 0]}
        />
        <group name="NurbsPath002" position={[0, -0.354, 0]} />
        <mesh
          name="Cylinder006"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder006.geometry}
          material={materials['Cable-white']}
          position={[0, -0.354, 0]}
        />
      </group>
    </group>
  );
}

useGLTF.preload('./model/monitor.glb');
