/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

export default function MousePad(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('./model/mouse-pad.glb');
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Cube100"
          castShadow
          receiveShadow
          geometry={nodes.Cube100.geometry}
          material={materials['Material.001']}
          position={[0.563, 1.344, 0.077]}
          scale={[1.28, 1, 1.28]}
        />
      </group>
    </group>
  );
}

useGLTF.preload('./model/mouse-pad.glb');
