/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

export default function Chair(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('./model/chair.glb');
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Cube189"
          castShadow
          receiveShadow
          geometry={nodes.Cube189.geometry}
          material={materials['Material.005']}
          position={[-0.524, 1.258, 1]}
          rotation={[-1.496, 0, 0]}
          scale={[1, 1, 0.514]}
        />
        <mesh
          name="Cube190"
          castShadow
          receiveShadow
          geometry={nodes.Cube190.geometry}
          material={materials['Material.005']}
          position={[-0.524, 0.921, 0.692]}
          scale={[1, 1, 1.35]}
        />
        <mesh
          name="Cube191"
          castShadow
          receiveShadow
          geometry={nodes.Cube191.geometry}
          material={materials['Material.005']}
          position={[-0.524, 1.015, 0.98]}
          rotation={[1.647, 0, 0]}
          scale={[1, 1, 0.514]}
        />
        <mesh
          name="Cube192"
          castShadow
          receiveShadow
          geometry={nodes.Cube192.geometry}
          material={materials['Material.005']}
          position={[-0.524, 1.5, 1.02]}
          rotation={[-1.496, 0, 0]}
          scale={[1, 1, 0.514]}
        />
        <mesh
          name="Cylinder009"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder009.geometry}
          material={materials['Cable-white']}
          position={[-0.526, 0.584, 0.722]}
          scale={[-0.045, -0.299, -0.045]}
        />
        <mesh
          name="Cylinder014"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder014.geometry}
          material={materials['Cable-white']}
          position={[-0.526, 0.395, 0.723]}
          rotation={[-2.081, 0, 0]}
          scale={[-0.045, -0.299, -0.045]}
        />
        <mesh
          name="Cylinder008"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder008.geometry}
          material={materials['Cable-white']}
          position={[-0.526, 0.395, 0.723]}
          rotation={[-1.742, -0.483, 1.214]}
          scale={[-0.045, -0.299, -0.045]}
        />
        <mesh
          name="Cylinder010"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder010.geometry}
          material={materials['Cable-white']}
          position={[-0.526, 0.395, 0.723]}
          rotation={[-1.146, -0.291, 2.576]}
          scale={[-0.045, -0.299, -0.045]}
        />
        <mesh
          name="Cylinder011"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder011.geometry}
          material={materials['Cable-white']}
          position={[-0.526, 0.395, 0.723]}
          rotation={[-1.146, 0.291, -2.576]}
          scale={[-0.045, -0.299, -0.045]}
        />
        <mesh
          name="Cylinder012"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder012.geometry}
          material={materials['Cable-white']}
          position={[-0.526, 0.395, 0.723]}
          rotation={[-1.742, 0.483, -1.214]}
          scale={[-0.045, -0.299, -0.045]}
        />
      </group>
    </group>
  );
}

useGLTF.preload('./model/chair.glb');
