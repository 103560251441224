/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

export default function Keyboard(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('./model/keyboard.glb');
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Cube102"
          castShadow
          receiveShadow
          geometry={nodes.Cube102.geometry}
          material={materials['Material.003']}
          position={[-0.055, -0.366, 0.171]}
          scale={[1.055, 1, 0.976]}
        />
        <mesh
          name="Cube103"
          castShadow
          receiveShadow
          geometry={nodes.Cube103.geometry}
          material={materials['Cable-white']}
          position={[0.148, 1.352, 0.146]}
          rotation={[0.027, 0, 0]}
          scale={[0.378, 0.42, 0.42]}
        />
        <mesh
          name="Cube104"
          castShadow
          receiveShadow
          geometry={nodes.Cube104.geometry}
          material={materials['Cable-white']}
          position={[0.103, 1.352, 0.124]}
          rotation={[0.027, 0, 0]}
          scale={[0.378, 0.42, 0.42]}
        />
        <mesh
          name="Cube105"
          castShadow
          receiveShadow
          geometry={nodes.Cube105.geometry}
          material={materials['Cable-white']}
          position={[0.06, 1.352, 0.146]}
          rotation={[0.027, 0, 0]}
          scale={[0.378, 0.42, 0.42]}
        />
        <mesh
          name="Cube106"
          castShadow
          receiveShadow
          geometry={nodes.Cube106.geometry}
          material={materials['Cable-white']}
          position={[0.015, 1.352, 0.146]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube107"
          castShadow
          receiveShadow
          geometry={nodes.Cube107.geometry}
          material={materials['Cable-white']}
          position={[-0.027, 1.352, 0.146]}
          rotation={[0.027, 0, 0]}
          scale={[0.372, 0.42, 0.42]}
        />
        <mesh
          name="Cube108"
          castShadow
          receiveShadow
          geometry={nodes.Cube108.geometry}
          material={materials['Cable-white']}
          position={[-0.2, 1.352, 0.146]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube109"
          castShadow
          receiveShadow
          geometry={nodes.Cube109.geometry}
          material={materials['Cable-white']}
          position={[-0.392, 1.352, 0.146]}
          rotation={[0.027, 0, 0]}
          scale={[0.372, 0.42, 0.42]}
        />
        <mesh
          name="Cube110"
          castShadow
          receiveShadow
          geometry={nodes.Cube110.geometry}
          material={materials['Cable-white']}
          position={[-0.46, 1.352, 0.146]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube111"
          castShadow
          receiveShadow
          geometry={nodes.Cube111.geometry}
          material={materials['Cable-white']}
          position={[-0.506, 1.352, 0.146]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube112"
          castShadow
          receiveShadow
          geometry={nodes.Cube112.geometry}
          material={materials['Cable-white']}
          position={[-0.552, 1.352, 0.146]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube113"
          castShadow
          receiveShadow
          geometry={nodes.Cube113.geometry}
          material={materials['Cable-white']}
          position={[-0.536, 1.353, 0.1]}
          rotation={[0.027, 0, 0]}
          scale={[0.832, 0.42, 0.42]}
        />
        <mesh
          name="Cube114"
          castShadow
          receiveShadow
          geometry={nodes.Cube114.geometry}
          material={materials['Cable-white']}
          position={[0.044, 1.353, 0.1]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube115"
          castShadow
          receiveShadow
          geometry={nodes.Cube115.geometry}
          material={materials['Cable-white']}
          position={[-0.002, 1.353, 0.1]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube116"
          castShadow
          receiveShadow
          geometry={nodes.Cube116.geometry}
          material={materials['Cable-white']}
          position={[-0.048, 1.353, 0.1]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube117"
          castShadow
          receiveShadow
          geometry={nodes.Cube117.geometry}
          material={materials['Cable-white']}
          position={[-0.095, 1.353, 0.1]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube118"
          castShadow
          receiveShadow
          geometry={nodes.Cube118.geometry}
          material={materials['Cable-white']}
          position={[-0.141, 1.353, 0.1]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube119"
          castShadow
          receiveShadow
          geometry={nodes.Cube119.geometry}
          material={materials['Cable-white']}
          position={[-0.187, 1.353, 0.1]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube120"
          castShadow
          receiveShadow
          geometry={nodes.Cube120.geometry}
          material={materials['Cable-white']}
          position={[-0.233, 1.353, 0.1]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube121"
          castShadow
          receiveShadow
          geometry={nodes.Cube121.geometry}
          material={materials['Cable-white']}
          position={[-0.279, 1.353, 0.1]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube122"
          castShadow
          receiveShadow
          geometry={nodes.Cube122.geometry}
          material={materials['Cable-white']}
          position={[-0.326, 1.353, 0.1]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube123"
          castShadow
          receiveShadow
          geometry={nodes.Cube123.geometry}
          material={materials['Cable-white']}
          position={[-0.372, 1.353, 0.1]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube124"
          castShadow
          receiveShadow
          geometry={nodes.Cube124.geometry}
          material={materials['Cable-white']}
          position={[-0.418, 1.353, 0.1]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube125"
          castShadow
          receiveShadow
          geometry={nodes.Cube125.geometry}
          material={materials['Cable-white']}
          position={[-0.464, 1.353, 0.1]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube126"
          castShadow
          receiveShadow
          geometry={nodes.Cube126.geometry}
          material={materials['Cable-white']}
          position={[0.09, 1.353, 0.1]}
          rotation={[0.027, 0, 0]}
          scale={[0.454, 0.42, 0.42]}
        />
        <mesh
          name="Cube127"
          castShadow
          receiveShadow
          geometry={nodes.Cube127.geometry}
          material={materials['Cable-white']}
          position={[0.111, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube128"
          castShadow
          receiveShadow
          geometry={nodes.Cube128.geometry}
          material={materials['Cable-white']}
          position={[0.065, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube129"
          castShadow
          receiveShadow
          geometry={nodes.Cube129.geometry}
          material={materials['Cable-white']}
          position={[0.019, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube130"
          castShadow
          receiveShadow
          geometry={nodes.Cube130.geometry}
          material={materials['Cable-white']}
          position={[-0.027, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube131"
          castShadow
          receiveShadow
          geometry={nodes.Cube131.geometry}
          material={materials['Cable-white']}
          position={[-0.074, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube132"
          castShadow
          receiveShadow
          geometry={nodes.Cube132.geometry}
          material={materials['Cable-white']}
          position={[-0.12, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube133"
          castShadow
          receiveShadow
          geometry={nodes.Cube133.geometry}
          material={materials['Cable-white']}
          position={[-0.166, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube134"
          castShadow
          receiveShadow
          geometry={nodes.Cube134.geometry}
          material={materials['Cable-white']}
          position={[-0.212, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube135"
          castShadow
          receiveShadow
          geometry={nodes.Cube135.geometry}
          material={materials['Cable-white']}
          position={[-0.258, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube136"
          castShadow
          receiveShadow
          geometry={nodes.Cube136.geometry}
          material={materials['Cable-white']}
          position={[-0.305, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube137"
          castShadow
          receiveShadow
          geometry={nodes.Cube137.geometry}
          material={materials['Cable-white']}
          position={[-0.351, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube138"
          castShadow
          receiveShadow
          geometry={nodes.Cube138.geometry}
          material={materials['Cable-white']}
          position={[-0.397, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube139"
          castShadow
          receiveShadow
          geometry={nodes.Cube139.geometry}
          material={materials['Cable-white']}
          position={[-0.443, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube140"
          castShadow
          receiveShadow
          geometry={nodes.Cube140.geometry}
          material={materials['Cable-white']}
          position={[-0.489, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube141"
          castShadow
          receiveShadow
          geometry={nodes.Cube141.geometry}
          material={materials['Cable-white']}
          position={[-0.531, 1.354, 0.054]}
          rotation={[0.027, 0, 0]}
          scale={[0.372, 0.42, 0.42]}
        />
        <mesh
          name="Cube142"
          castShadow
          receiveShadow
          geometry={nodes.Cube142.geometry}
          material={materials['Cable-white']}
          position={[0.149, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube143"
          castShadow
          receiveShadow
          geometry={nodes.Cube143.geometry}
          material={materials['Cable-white']}
          position={[0.103, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube144"
          castShadow
          receiveShadow
          geometry={nodes.Cube144.geometry}
          material={materials['Cable-white']}
          position={[0.057, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube145"
          castShadow
          receiveShadow
          geometry={nodes.Cube145.geometry}
          material={materials['Cable-white']}
          position={[0.01, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube146"
          castShadow
          receiveShadow
          geometry={nodes.Cube146.geometry}
          material={materials['Cable-white']}
          position={[-0.036, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube147"
          castShadow
          receiveShadow
          geometry={nodes.Cube147.geometry}
          material={materials['Cable-white']}
          position={[-0.082, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube148"
          castShadow
          receiveShadow
          geometry={nodes.Cube148.geometry}
          material={materials['Cable-white']}
          position={[-0.128, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube149"
          castShadow
          receiveShadow
          geometry={nodes.Cube149.geometry}
          material={materials['Cable-white']}
          position={[-0.174, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube150"
          castShadow
          receiveShadow
          geometry={nodes.Cube150.geometry}
          material={materials['Cable-white']}
          position={[-0.221, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube151"
          castShadow
          receiveShadow
          geometry={nodes.Cube151.geometry}
          material={materials['Cable-white']}
          position={[-0.267, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube152"
          castShadow
          receiveShadow
          geometry={nodes.Cube152.geometry}
          material={materials['Cable-white']}
          position={[-0.313, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube153"
          castShadow
          receiveShadow
          geometry={nodes.Cube153.geometry}
          material={materials['Cable-white']}
          position={[-0.359, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube154"
          castShadow
          receiveShadow
          geometry={nodes.Cube154.geometry}
          material={materials['Cable-white']}
          position={[-0.405, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube155"
          castShadow
          receiveShadow
          geometry={nodes.Cube155.geometry}
          material={materials['Cable-white']}
          position={[-0.452, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube156"
          castShadow
          receiveShadow
          geometry={nodes.Cube156.geometry}
          material={materials['Cable-white']}
          position={[-0.547, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={[0.346, 0.42, 0.42]}
        />
        <mesh
          name="Cube157"
          castShadow
          receiveShadow
          geometry={nodes.Cube157.geometry}
          material={materials['Cable-white']}
          position={[0.136, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube158"
          castShadow
          receiveShadow
          geometry={nodes.Cube158.geometry}
          material={materials['Cable-white']}
          position={[0.078, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube159"
          castShadow
          receiveShadow
          geometry={nodes.Cube159.geometry}
          material={materials['Cable-white']}
          position={[0.031, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube160"
          castShadow
          receiveShadow
          geometry={nodes.Cube160.geometry}
          material={materials['Cable-white']}
          position={[-0.015, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube161"
          castShadow
          receiveShadow
          geometry={nodes.Cube161.geometry}
          material={materials['Cable-white']}
          position={[-0.061, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube162"
          castShadow
          receiveShadow
          geometry={nodes.Cube162.geometry}
          material={materials['Cable-white']}
          position={[-0.107, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube163"
          castShadow
          receiveShadow
          geometry={nodes.Cube163.geometry}
          material={materials['Cable-white']}
          position={[-0.153, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube164"
          castShadow
          receiveShadow
          geometry={nodes.Cube164.geometry}
          material={materials['Cable-white']}
          position={[-0.2, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube165"
          castShadow
          receiveShadow
          geometry={nodes.Cube165.geometry}
          material={materials['Cable-white']}
          position={[-0.246, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube166"
          castShadow
          receiveShadow
          geometry={nodes.Cube166.geometry}
          material={materials['Cable-white']}
          position={[-0.292, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube167"
          castShadow
          receiveShadow
          geometry={nodes.Cube167.geometry}
          material={materials['Cable-white']}
          position={[-0.338, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube168"
          castShadow
          receiveShadow
          geometry={nodes.Cube168.geometry}
          material={materials['Cable-white']}
          position={[-0.384, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube169"
          castShadow
          receiveShadow
          geometry={nodes.Cube169.geometry}
          material={materials['Cable-white']}
          position={[-0.431, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube170"
          castShadow
          receiveShadow
          geometry={nodes.Cube170.geometry}
          material={materials['Cable-white']}
          position={[-0.477, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube171"
          castShadow
          receiveShadow
          geometry={nodes.Cube171.geometry}
          material={materials['Cable-white']}
          position={[-0.523, 1.357, -0.038]}
          rotation={[0.027, 0, 0]}
          scale={[0.445, 0.42, 0.42]}
        />
        <mesh
          name="Cube172"
          castShadow
          receiveShadow
          geometry={nodes.Cube172.geometry}
          material={materials['Cable-white']}
          position={[0.145, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube173"
          castShadow
          receiveShadow
          geometry={nodes.Cube173.geometry}
          material={materials['Cable-white']}
          position={[0.099, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube174"
          castShadow
          receiveShadow
          geometry={nodes.Cube174.geometry}
          material={materials['Cable-white']}
          position={[0.052, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube175"
          castShadow
          receiveShadow
          geometry={nodes.Cube175.geometry}
          material={materials['Cable-white']}
          position={[0.006, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube176"
          castShadow
          receiveShadow
          geometry={nodes.Cube176.geometry}
          material={materials['Cable-white']}
          position={[-0.04, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube177"
          castShadow
          receiveShadow
          geometry={nodes.Cube177.geometry}
          material={materials['Cable-white']}
          position={[-0.086, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube178"
          castShadow
          receiveShadow
          geometry={nodes.Cube178.geometry}
          material={materials['Cable-white']}
          position={[-0.132, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube179"
          castShadow
          receiveShadow
          geometry={nodes.Cube179.geometry}
          material={materials['Cable-white']}
          position={[-0.179, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube180"
          castShadow
          receiveShadow
          geometry={nodes.Cube180.geometry}
          material={materials['Cable-white']}
          position={[-0.225, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube181"
          castShadow
          receiveShadow
          geometry={nodes.Cube181.geometry}
          material={materials['Cable-white']}
          position={[-0.271, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube182"
          castShadow
          receiveShadow
          geometry={nodes.Cube182.geometry}
          material={materials['Cable-white']}
          position={[-0.317, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube183"
          castShadow
          receiveShadow
          geometry={nodes.Cube183.geometry}
          material={materials['Cable-white']}
          position={[-0.363, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube184"
          castShadow
          receiveShadow
          geometry={nodes.Cube184.geometry}
          material={materials['Cable-white']}
          position={[-0.41, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube185"
          castShadow
          receiveShadow
          geometry={nodes.Cube185.geometry}
          material={materials['Cable-white']}
          position={[-0.456, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube186"
          castShadow
          receiveShadow
          geometry={nodes.Cube186.geometry}
          material={materials['Cable-white']}
          position={[-0.498, 1.356, 0.008]}
          rotation={[0.027, 0, 0]}
          scale={0.42}
        />
        <mesh
          name="Cube187"
          castShadow
          receiveShadow
          geometry={nodes.Cube187.geometry}
          material={materials['Cable-white']}
          position={[-0.554, 1.358, -0.085]}
          rotation={[0.027, 0, 0]}
          scale={[0.433, 0.42, 0.42]}
        />
        <mesh
          name="Cube188"
          castShadow
          receiveShadow
          geometry={nodes.Cube188.geometry}
          material={materials['Cable-white.001']}
          position={[0.103, 1.352, 0.146]}
          rotation={[0.027, 0, 0]}
          scale={[0.378, 0.42, 0.42]}
        />
      </group>
    </group>
  );
}

useGLTF.preload('./model/keyboard.glb');
